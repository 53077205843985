.login-body {
    background: linear-gradient( to bottom, #f4f6fd, #6871e1), url(../../public/assets/img/boxbg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cartoon-img img {
    min-height: 300px;
    object-fit: contain;
    height: auto;
}

.login-cartoon-img {
    bottom: 50px;
}


/* media query*/

@media (max-width: 767px) {
    .card-body p {
        padding-bottom: 15px !important;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .cartoon-img {
        bottom: 0;
    }
}