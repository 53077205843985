/* main body section*/

.profile-greeting {
    position: relative;
    background-color: #6871e1;
    color: #fff;
    margin-bottom: 30px;
    margin-top: 10px;
    border: none;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    letter-spacing: 0.5px;
    border-radius: 15px;
    -webkit-box-shadow: 0px 4px 30px rgb(95 94 231 / 7%);
    box-shadow: 0px 4px 30px rgb(95 94 231 / 7%);
}

.profile-greeting .card-body {
    padding: 40px;
}

.profile-greeting .greeting-user h1 {
    margin-bottom: 12px;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0;
    color: #fff;
}

.profile-greeting .greeting-user p {
    font-weight: 400;
    color: rgba(255, 255, 255, 0.67);
    margin-bottom: 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 18px;
}

.profile-greeting .greeting-user .btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 35px;
    line-height: 1;
    padding: 13px 17px;
    font-size: 13px;
    font-weight: 600;
    border-color: #fff;
    color: #fff;
    background-color: transparent;
}

.profile-greeting .greeting-user .btn i {
    margin-left: 10px;
    font-weight: 700;
}


/* media query */

@media (max-width: 767px) {
    .profile-greeting {
        height: auto;
    }
    .profile-greeting .card-body {
        padding: 20px;
    }
    .profile-greeting .cartoon-img img {
        height: 100px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .profile-greeting .cartoon-img img {
        height: 120px;
    }
}