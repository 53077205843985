/* header css start */

ul.navbar-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
}

li.nav-item {
    display: inline-block;
}

.navbar-light .navbar-nav .nav-link {
    color: #2c323f !important;
    padding: 6px 10px;
    margin-right: 5px;
    display: inline-block;
    position: relative;
    font-family: "Roboto", sans-serif, sans-serif;
    letter-spacing: 0.5px;
    font-size: 15px;
    font-weight: 500;
}

.navbar-light .navbar-nav .nav-link.active {
    color: #6871e1 !important;
}

.left-side-header .input-group span svg {
    height: 18px;
    width: 18px;
    stroke: #1e2f65;
}

.left-side-header .input-group input {
    border: 1px solid #c9c9c9;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 25px;
    max-width: 100%;
    margin-left: 18px;
    border-left: none;
    padding: 10px 0;
    background-color: transparent;
    font-size: 16px;
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.left-side-header .input-group span {
    background: transparent;
    border-right: none;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    border-color: #c9c9c9 !important;
    padding-left: 20px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

header {
    margin-left: 280px;
    width: calc(100% - 280px);
}

.navbar-light .navbar-toggler {
    right: 5px;
}

.navbar-light.sticky-top {
    background: #fff;
    z-index: 111;
}

.nav-link {
    color: #222222;
}

.nav-link svg {
    margin-right: 10px;
    vertical-align: bottom;
    float: none;
    width: 19px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.mobile-logo {
    display: none;
}


/* media query */

@media (max-width: 767px) {
    ul.navbar-nav {
        flex-wrap: wrap;
    }
    .navbar-light .navbar-nav .nav-link {
        font-size: 14px;
    }
    .mobile-logo {
        display: block;
    }
    .mobile-logo img {
        max-width: 100px;
        object-fit: contain;
    }
    .sidebar-sticky .navbar-brand {
        display: none;
    }
}