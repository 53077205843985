/* -----------------------------------------------------------------------------------*/

/*=======  COMMON CSS  =======*/

body {
  font-family: "Roboto", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

a:focus,
input:focus,
textarea:focus,
button:focus,
select:focus {
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-family: "Roboto", sans-serif;
}

input,
textarea,
button,
select {
  border: none;
}

input,
textarea,
select {
  width: 100%;
  background-color: #fff;
  padding: 0 25px;
  height: 70px;
  font-weight: 700;
  font-size: 15px;
  color: #616161;
  font-family: "Roboto", sans-serif;
}

::-webkit-input-placeholder {
  opacity: 1;
}

:-ms-input-placeholder {
  opacity: 1;
}

::-ms-input-placeholder {
  opacity: 1;
}

::placeholder {
  opacity: 1;
}

::-webkit-scrollbar {
  background-color: #ccc;
  width: 8px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #245ca5;
}

a:focus,
a:hover {
  text-decoration: none;
}

i,
span,
a,
Link {
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin: 0px;
  font-family: "Roboto", sans-serif;
}

h1,
h1 a,
h2,
h2 a,
h3,
h3 a,
h4,
h4 a,
h5,
h5 a,
h6,
h6 a {
  color: #14212b;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

p {
  margin: 0px;
  font-family: "Roboto", sans-serif;
}

a {
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  color: #6871e1;
  font-weight: bold;
}

a,
a:hover {
  color: #6871e1;
  font-family: "Roboto", sans-serif;
}

.btn.btn-primary {
  background-color: #6871e1;
  border-color: #6871e1;
}

button,
input,
optgroup,
select,
textarea {
  font-family: "Roboto", sans-serif;
}

.main {
  height: calc(100vh - 54px);
  margin-left: 280px;
  position: relative;
}

.main-body {
  background-color: #f4f6fd;
  font-family: "Roboto", sans-serif;
  min-height: 100%;
  padding-bottom: 50px !important;
}

form {
  font-family: "Roboto", sans-serif;
}

/* table css */

.table {
  border: 0;
  box-shadow: 0px 4px 30px rgb(95 94 231 / 7%);
}

.table td,
.table th {
  padding: 15px;
  vertical-align: middle;
  font-size: 14px;
  vertical-align: middle;
  letter-spacing: 0.5px;
  line-height: 1.2;
  color: #1e2f65;
  letter-spacing: 1px;
}

.table td:last-child {
  row-gap: 10px;
}

.table thead th {
  background: #6871e1;
  color: #fff;
  font-weight: bold;
  border: 0;
}

.table thead th:nth-child(1) {
  border-top-left-radius: 15px;
}

.table thead th:last-child {
  border-top-right-radius: 15px;
  width: 100px;
}

.table tr td {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}

.table tr td:last-child {
  display: flex;
  flex-direction: column;
}

/* form css*/

.form-group {
  margin-bottom: 40px;
}

.form-control {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #181849;
  box-shadow: none;
}

.group {
  position: relative;
}

input:focus {
  outline: none;
}

label {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 0;
  transition: 0.4s ease all;
  -moz-transition: 0.4s ease all;
  -webkit-transition: 0.4s ease all;
}

input:not(:placeholder-shown)~label,
input:focus~label {
  top: -20px;
  font-size: 14px;
  color: #6871e1;
}

.bar {
  position: relative;
  display: block;
  width: 100%;
}

.bar:before,
.bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 0px;
  position: absolute;
  background: #6871e1;
  transition: 0.4s ease all;
  -moz-transition: 0.4s ease all;
  -webkit-transition: 0.4s ease all;
}

.bar:before {
  left: 50%;
}

.bar:after {
  right: 50%;
}

input:focus~.bar:before,
input:focus~.bar:after {
  width: 50%;
}

.highlight {
  position: absolute;
  /* height:60%; */
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

/* sidebar css */

#sidebarMenu {
  background-color: #fff !important;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 222;
  line-height: inherit;
  text-align: left;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-box-shadow: 0 0 21px 0 rgb(89 102 122 / 10%);
  box-shadow: 0 0 21px 0 rgb(89 102 122 / 10%);
  width: 280px;
}

#sidebarMenu ul {
  padding: 0 20px;
}

.navbar-brand img {
  max-width: 150px;
  object-fit: contain;
}

.sidebar-sticky .nav-link.active {
  background-color: #f4f6fd;
  color: #6871e1;
  border-radius: 10px;
}

.sidebar-sticky .nav-link {
  padding: 12px 15px;
  color: #222222;
  font-weight: 500;
  text-decoration: none;
  letter-spacing: 1px;
  font-size: 15px;
}

.sidebar-sticky .nav-link.active svg {
  stroke: #6871e1;
}

.desktop-hide {
  display: none !important;
}

.sidebar-sticky .nav-link:hover {
  background-color: #f4f6fd;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #6871e1;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 10px;
}

.sidebar-sticky .nav-link:hover svg {
  stroke: #6871e1;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/*dropdown*/

.profile-dropdown {
  width: 160px;
  top: 40px;
  padding: 0 10px;
  right: 0;
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 0 20px rgb(89 102 122 / 10%);
  position: absolute;
  z-index: 8;
  background-color: #fff;
  transition: all linear 0.3s;
}

.onhover-dropdown:hover .onhover-show-div {
  opacity: 1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  visibility: visible;
  border-radius: 5px;
  overflow: hidden;
}

.onhover-dropdown {
  position: relative;
}

.profile-dropdown li {
  padding: 10px !important;
  border-bottom: 1px solid #ecf3fa;
  -webkit-transform: translateY(0) scale(1);
  transform: translateY(0) scale(1);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  overflow: hidden;
}

.profile-dropdown li a {
  width: 100%;
  letter-spacing: 0.3px;
  font-family: "Roboto", sans-serif, sans-serif;
  font-size: 14px;
  color: #2c323f;
  font-weight: 500;
}

.profile-dropdown li svg {
  width: 16px;
  vertical-align: bottom;
  stroke: #2c323f;
  margin-right: 5px;
}

.LoaderBackground {
  height: 100%;
  width: 100%;
  background: #fff;
  z-index: 1;
  display: flex;
  align-items: center;
  position: absolute;
  border-radius: 15px;
}

.disableLabel {
  left: 0px;
  top: -18px;
  font-size: 14px;
}

/* active state */

input:focus~.highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

h3,
h4 {
  font-size: 22px;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  font-weight: 500;
  line-height: 1.2;
  color: #6871e1;
}

.custom-file-label {
  border-bottom: 1px solid #181849;
  border-radius: unset;
  color: #999 !important;
  font-size: 18px !important;
  font-weight: normal;
  position: absolute;
  padding: 0 5px;
  border-left: 0;
  border-top: 0;
  border-right: 0;
  top: -3px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.edit-business-logo {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 40px;
  margin: 0px 0px 25px 10px;
}

.loadingWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin: 0px 0px 25px 10px;
}

.business-form .form-group {
  margin-bottom: 50px;
}

.business-form .text-group {
  margin-top: 0px;
  padding: 0 10px;
}

.business-form .text-group label {
  left: 15px;
}

.custom-file-input:focus~.custom-file-label {
  box-shadow: unset;
  border-color: #181849;
}

.form-row>[class*="col-"] {
  padding-right: 15px;
  padding-left: 15px;
}

/* ANIMATIONS ================ */

@-webkit-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }

  to {
    width: 0;
    background: transparent;
  }
}

@-moz-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }

  to {
    width: 0;
    background: transparent;
  }
}

@keyframes inputHighlighter {
  from {
    background: #5264ae;
  }

  to {
    width: 0;
    background: transparent;
  }
}

.text-group textarea {
  display: block;
  background: none;
  padding: 0.125rem 0.125rem 0.0625rem;
  border-width: 0;
  border-color: transparent;
  line-height: 1.9;
  width: 100%;
  -webkit-transition: all 0.28s ease;
  transition: all 0.28s ease;
  box-shadow: none;
  border-bottom: 1px solid #181849;
}

.text-group textarea:not(:placeholder-shown)~.input-label,
.text-group textarea:focus~.input-label,
.text-group textarea.form-file~.input-label,
.text-group textarea.has-value~.input-label {
  font-size: 14px;
  color: gray;
  top: -1rem;
  left: 0;
}

.text-group textarea:not(:placeholder-shown)~.input-label,
.text-group textarea:focus~.input-label {
  color: #6871e1;
}

.text-group textarea:not(:placeholder-shown)~.input-label,
.text-group textarea:focus~.bar::before {
  width: 100%;
  left: 0;
}

.text-group {
  position: relative;
  margin-top: 20px;
  margin-bottom: 50px;
}

select {
  display: block;
  background: none;
  padding: 0.125rem 0.125rem 0.0625rem;
  border-width: 0;
  border-color: transparent;
  line-height: 1.9;
  width: 100%;
  -webkit-transition: all 0.28s ease;
  transition: all 0.28s ease;
  box-shadow: none;
}

select:focus~.input-label,
select:valid~.input-label,
select.form-file~.input-label,
select.has-value~.input-label {
  font-size: 14px;
  color: gray;
  top: -1rem;
  left: 0;
}

select:focus~.input-label {
  color: #6871e1;
}

select:focus~.bar::before {
  width: 100%;
  left: 0;
}

.form-control:focus {
  box-shadow: unset;
}

.custom-file-label::after {
  top: -5px;
}

.logo-img-cover {
  height: 35px;
  width: 35px;
  object-fit: cover;
  border-radius: 100px;
}

/* form css */

.form-holder .card-body {
  padding: 30px 30px 10px 30px;
  background: url(../public/assets/img/premium-ace.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: auto 200px;
  border-radius: 15px;
}

.comment-box {
  border-radius: 15px;
  box-shadow: 0px 4px 30px rgb(95 94 231 / 7%);
}

.form-holder.card {
  border-radius: 15px;
  box-shadow: 0px 4px 30px rgb(95 94 231 / 7%);
}

.btn {
  padding: 0.375rem 1.75rem;
  text-transform: uppercase;
}

.btn:focus {
  border: 0;
  box-shadow: unset;
  outline: 0;
}

.btn:hover {
  background-color: #5655e5 !important;
}

.cartoon-img {
  position: absolute;
  bottom: -5px;
  right: -20px;
}

@media (max-width: 1200px) {
  .cartoon-img {
    opacity: 0;
  }
}

.sticky-form {
  height: 80vh;
  top: 7%;
}

@keyframes mymove {
  0% {
    right: 20px;
  }

  50% {
    right: 80px;
  }

  100% {
    right: 20px;
  }
}

@keyframes mymovemobile {
  0% {
    right: 0px;
  }

  50% {
    right: 20px;
  }

  100% {
    right: 20px;
  }
}

.noText {
  margin-top: 20px;
}

.table td a {
  padding: 8px 10px;
  font-size: 10px;
}

table {
  width: 99.99% !important;
}

table tr td {
  border: 1px solid rgb(0 0 0 / 5%);
}

.loader {
  border: 4px solid #d4d4d4;
  border-radius: 50%;
  border-top: 4px solid #6871e1;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* popup css*/

.custom-modal {
  background: none;
  border: none;
}

.modal-confirm {
  color: #636363;
  width: 400px;
}

.modal-confirm .modal-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
  text-align: center;
  font-size: 14px;
}

.modal-confirm .modal-header {
  border-bottom: none;
  position: relative;
}

.modal-confirm h4 {
  text-align: center;
  font-size: 26px;
  margin: 30px 0 -10px;
}

.modal-confirm .close {
  position: absolute;
  top: -5px;
  right: -2px;
}

.modal-confirm .modal-body {
  color: #999;
}

.modal-confirm .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
  padding: 10px 15px 25px;
}

.modal-confirm .modal-footer a {
  color: #999;
}

.modal-confirm .icon-box {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 9;
  text-align: center;
  border: 3px solid #f15e5e;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-confirm .icon-box svg {
  stroke: #f15e5e;
  font-size: 46px;
  display: inline-block;
}

.modal-confirm .btn,
.modal-confirm .btn:active {
  color: #fff;
  border-radius: 4px;
  background: #60c7c1;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  min-width: 120px;
  border: none;
  min-height: 40px;
  border-radius: 3px;
  margin: 0 5px;
}

.modal-confirm .btn-secondary {
  background: #c1c1c1;
}

.modal-confirm .btn-secondary:hover,
.modal-confirm .btn-secondary:focus {
  background: #a8a8a8;
}

.modal-confirm .btn-danger {
  background: #f15e5e;
}

.modal-confirm .btn-danger:hover,
.modal-confirm .btn-danger:focus {
  background: #ee3535;
}

.trigger-btn {
  display: inline-block;
  margin: 100px auto;
}

@media (max-width: 767px) {
  .form-holder .card-body {
    padding: 20px;
  }

  .cartoon-img {
    animation: mymovemobile 6s infinite;
  }

  .btn {
    font-size: 15px;
  }

  .text-group {
    margin-bottom: 30px;
  }

  label {
    font-size: 15px;
  }

  .table td a {
    width: 100%;
  }

  .main-body {
    padding: 20px !important;
  }

  .table td,
  .table th {
    padding: 10px;
  }

  .table td a {
    margin-bottom: 5px;
  }

  .form-holder.card {
    margin-top: 20px !important;
  }

  .cartoon-img img {
    height: 150px;
    object-fit: contain;
  }

  #sidebarMenu {
    top: 55px;
    width: 100%;
    z-index: 11111111;
    box-shadow: unset;
  }

  .form-group {
    margin-bottom: 20px;
  }

  .desktop-hide {
    display: block !important;
  }

  ul.navbar-nav {
    display: none !important;
  }

  .main {
    margin-left: 0px;
  }

  .form-holder .card-body {
    background-size: auto 100px;
  }

  .user-div {
    flex-direction: column;
    align-items: flex-start;
  }

  .user-div .btn {
    margin-top: 15px;
  }

  .modal-confirm {
    width: auto;
  }

  .modal-confirm .btn-danger {
    margin-top: 15px;
  }

  .custom-file-input:lang(en)~.custom-file-label::after,
  .custom-file-label {
    font-size: 15px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #sidebarMenu ul {
    padding-left: 10px;
  }

  label {
    font-size: 16px;
  }

  .custom-file-input:lang(en)~.custom-file-label::after {
    font-size: 14px !important;
  }

  .custom-file-label {
    font-size: 16px !important;
  }

  #sidebarMenu {
    width: 220px;
  }

  .form-holder .card-body {
    background-size: auto 150px;
  }

  .main-body {
    padding: 20px !important;
  }

  .main {
    margin-left: 220px;
  }

  .table td a {
    margin-bottom: 5px;
    width: 100%;
  }

  .cartoon-img img {
    object-fit: contain;
    height: 180px;
  }

  .tab-menu {
    display: block !important;
  }

  ul.navbar-nav .nav-item:nth-child(-n + 3) {
    display: none;
  }
}

@media (max-width: 991px) {
  .sticky-form {
    height: auto;
    position: unset;
    z-index: auto;
  }
}

@media screen and (min-width: 1081px) and (max-width: 1600px) {
  .table td a {
    width: 45%;
  }

  .table td a:last-child,
  .table td a:nth-last-child(2) {
    width: 100%;
  }

  .cartoon-img img {
    height: 220px;
    object-fit: contain;
  }

  .text-group {
    margin-bottom: 30px;
  }

  .form-holder .card-body {
    background-size: auto 150px;
  }
}

.login-waning {
  font-size: 14px;
  color: red;
  margin-top: -30px;
  margin-bottom: 23px;
}

.Warning {
  font-size: 12px;
  color: red;
  margin-left: 5px;
}

.show-photo-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #e9e9e9;
  margin-top: 13px;
  border-radius: 9px;
  cursor: pointer;
}

.show-photo-button-row {
  margin: 0px;
}

.comments-Images {
  height: 154px !important;
  width: 100% !important;
  margin: auto !important;
  margin-top: 6px !important;
  background: #f7f7f7 !important;
  padding: 10px !important;
}

.reload-svgIcon {
  height: 20px;
  transform: rotate(36deg);
  margin-left: auto;
  cursor: pointer;
}

.reload-svgIcon:hover {
  height: 21px;
  transform: rotate(36deg);
  margin-left: auto;
  cursor: pointer;
}

.reload-svgIcon:hover path {
  fill: #404abb;
}

.top-notification {
  color: rgb(0, 0, 0);
  position: absolute;
  text-align: center;
  z-index: 999;
  width: 100%;
  display: flex;
  justify-content: center;
}

.top-notification .message {
  border-radius: 5px;
  width: max-content;
  padding: 5px 25px;
  transition: 1s;
  transform: translateY(30px);
  width: fit-content;
  position: fixed;
}

.top-notification .green {
  background-color: #d1ffd9;
  border: 1px solid #1b8a28;
  color: #1b8a28;
}

.top-notification .red {
  background-color: #ffd1d1;
  border: 1px solid #8a1b1b;
  color: #aa1818;
}

.landing-img {
  max-height: 325px;
  object-fit: contain;
  margin-bottom: -20px;
}