.user-card-full {
  overflow: hidden;
}

.card {
  border-radius: 15px;
  -webkit-box-shadow: 0px 4px 30px rgb(95 94 231 / 7%);
  box-shadow: 0px 4px 30px rgb(95 94 231 / 7%);
  margin-bottom: 30px;
  margin-top: 30px;
}

.user-name {
  font-size: 22px;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  font-weight: 500;
  line-height: 1.2;
  color: #fff;
  margin: 20px 0px 0px 0px;
}

h6 {
  font-size: 22px;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  font-weight: 500;
  line-height: 1.2;
  color: #000;
  border: 1px solid #6871e1;
  padding: 10px;
  outline: 0;
  border-radius: 15px;
}

.HeadingH6 {
  border: 0px solid #6871e1 !important;
  padding: 0px;
}

h6:focus-visible {
  border: 1px solid #6871e1;
  padding: 10px;
  outline: 0;
  border-radius: 15px;
}

.social-link {
  text-align: center;
  display: block;
  margin: 0 auto;
  padding: 40px;
}

.social-link svg {
  stroke: #fff;
}

.user-card-full .user-profile {
  border-radius: 5px 0 0 5px;
}

.bg-c-lite-green {
  background: #6871e1;
}

.user-profile {
  padding: 20px 0;
}

.card-block {
  padding: 40px;
}

.img-cover {
  height: 100px;
  width: 100px;
  margin: auto;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-img {
  border-radius: 5px;
  width: 100px;
  height: 100px;
  object-fit: contain;
  position: absolute;
  opacity: 0;
}

.img-radius {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.card .card-block p {
  font-size: 18px;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}

.b-b-default {
  border-bottom: 1px solid #e0e0e0;
}

.text-muted {
  color: #919aa3 !important;
  font-size: 18px;
}

.text-immuted {
  color: #272828 !important;
  font-size: 18px;
}

.b-b-default {
  border-bottom: 1px solid #e0e0e0;
}

.user-card-full .social-link li {
  display: inline-block;
}

.user-card-full .social-link li a {
  font-size: 20px;
  margin: 0 10px 0 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 767px) {
  .card-block {
    padding: 20px;
  }

  .col-sm-12 {
    margin-bottom: 15px;
  }
}